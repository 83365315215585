
























































































import { RouterNames } from "@/router/routernames";
import { MedicionesDto } from "@/shared/dtos/mediciones-dto";
import EntrevistaModule from "@/store/modules/entrevista-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class AddNewDataSeguimiento extends Vue {
  public created() {
    EntrevistaModule.getultimaentrevista();
  }

  public get data_seg() {
    return EntrevistaModule.entrevista;
  }

  public get stilo() {
    //this.cita.id_nutricionista
    let h = this.$vuetify.breakpoint.height - 155;
    return "height:" + h + "px;overflow: auto;";
  }

  public get step() {
    return 0.01;
  }

  public save() {
    EntrevistaModule.guardarentrevista(this.data_seg).then(() =>
      this.$router.push({ name: RouterNames.Home })
    );
  }
}
