var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"m-shadow mt-5"},[_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar "),_c('v-icon',[_vm._v("save")])],1)],1),_c('v-card-text',{style:(_vm.stilo)},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("Peso ("+_vm._s(_vm.data_seg.peso)+" Kg)")]),_c('v-slider',{attrs:{"step":_vm.step,"thumb-label":"","min":"10","max":"300"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}]),model:{value:(_vm.data_seg.peso),callback:function ($$v) {_vm.$set(_vm.data_seg, "peso", $$v)},expression:"data_seg.peso"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("Cintura ("+_vm._s(_vm.data_seg.cintura)+" Cm)")]),_c('v-slider',{attrs:{"step":_vm.step,"thumb-label":"","min":"0","max":"200"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}]),model:{value:(_vm.data_seg.cintura),callback:function ($$v) {_vm.$set(_vm.data_seg, "cintura", $$v)},expression:"data_seg.cintura"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("Cadera ("+_vm._s(_vm.data_seg.cadera)+" Cm)")]),_c('v-slider',{attrs:{"step":_vm.step,"thumb-label":"","min":"0","max":"200"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}]),model:{value:(_vm.data_seg.cadera),callback:function ($$v) {_vm.$set(_vm.data_seg, "cadera", $$v)},expression:"data_seg.cadera"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("Muñeca ("+_vm._s(_vm.data_seg.muñeca)+" Cm)")]),_c('v-slider',{attrs:{"step":_vm.step,"thumb-label":"","min":"0","max":"80"},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}]),model:{value:(_vm.data_seg.muñeca),callback:function ($$v) {_vm.$set(_vm.data_seg, "muñeca", $$v)},expression:"data_seg.muñeca"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("Altura ("+_vm._s(_vm.data_seg.altura)+" m)")]),_c('v-slider',{attrs:{"thumb-label":"","step":_vm.step,"min":"0","max":"3"},model:{value:(_vm.data_seg.altura),callback:function ($$v) {_vm.$set(_vm.data_seg, "altura", $$v)},expression:"data_seg.altura"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }